<template>
	<div class="ucenter">
		<van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('推荐规则')" left-arrow
			@click-left="onClickLeft" >
		</van-nav-bar>
		<div class="wrapper" style="font-size: 14px;padding: 0" v-html="html">
<!--			<div class="rule-cell">-->
<!--				<div class="hd">{{$t('个人推荐奖励规则')}}</div>-->
<!--				<div class="bd">{{$t('个人推荐奖励规则描述')}}</div>-->
<!--			</div>-->
<!--			<div class="rule-cell">-->
<!--				<div class="hd">{{$t('推荐奖励')}}</div>-->
<!--				<div class="bd">{{$t('推荐奖励描述')}}</div>-->
<!--			</div>-->
<!--			<div class="rule-cell">-->
<!--				<table class="rule-table">-->
<!--					<thead>-->
<!--						<tr>-->
<!--							<td>{{$t('等级')}}</td>-->
<!--							<td>{{$t('成员')}}</td>-->
<!--							<td>{{$t('推荐奖励')}}</td>-->
<!--						</tr>-->
<!--					</thead>-->
<!--					<tbody>-->
<!--						<tr v-for="(item,index) in 5" :key="index">-->
<!--							<td>T{{index+1}}</td>-->
<!--							<td>{{$t('1个')}}</td>-->
<!--							<td>{{$t('T' + (index + 1) + '奖励')}}</td>-->
<!--						</tr>-->
<!--						<tr>-->
<!--							<td colspan="3" class="table-tip">-->
<!--								<div class="vhd">{{$t('温馨提示')}}</div>-->
<!--								<div class="vbd">{{$t('温馨提示描述1')}}</div>-->
<!--							</td>-->
<!--						</tr>-->
<!--					</tbody>-->
<!--				</table>-->
<!--			</div>-->
<!--			<div class="rule-cell">-->
<!--				<div class="hd">{{$t('团队奖励')}}</div>-->
<!--				<div class="bd">{{$t('团队奖励描述')}}</div>-->
<!--			</div>-->
<!--			<div class="rule-cell">-->
<!--				<table class="rule-table">-->
<!--					<thead>-->
<!--						<tr>-->
<!--							<td>{{$t('你的级别')}}</td>-->
<!--							<td>{{$t('直接下级')}}</td>-->
<!--							<td>{{$t('二级下级')}}</td>-->
<!--							<td>{{$t('三级下级')}}</td>-->
<!--						</tr>-->
<!--					</thead>-->
<!--					<tbody>-->
<!--						<tr v-for="(item,index) in 5" :key="index">-->
<!--							<td>{{(index +1) + $t('级')}}</td>-->
<!--							<td>{{$t((index + 1) + '直接百分比')}}</td>-->
<!--							<td>{{$t((index + 1) + '二级百分比')}}</td>-->
<!--							<td>{{$t((index + 1) + '三级百分比')}}</td>-->
<!--						</tr>-->
<!--						<tr>-->
<!--							<td colspan="4" class="table-tip">-->
<!--								<div class="vhd">{{$t('温馨提示')}}</div>-->
<!--								<div class="vbd">{{$t('温馨提示描述2')}}</div>-->
<!--							</td>-->
<!--						</tr>-->
<!--					</tbody>-->
<!--				</table>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				show: false,
				name: '',
				html:'',
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			getData() {
				this.$axios
						.get('/help/get_content?type=12')
						.then((res) => {
							this.html = res.data;
						});
			}
		},
		mounted() {
			this.getData();
		}
	}
</script>
